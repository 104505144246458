// js
AOS.init();

$(document).ready(function() {

  $(".hamburger").click(function() {
    $(this).toggleClass("is-active");
    $(".box-menu").toggleClass("open");
    $("header").toggleClass("open-menu");
    $("html").toggleClass("hidden-overflow");

  });

  // $(".btn-lang").click(function(e) {
  //   $(this).toggleClass("show");
  //   e.stopPropagation();
  // });

  // $(document).click(function(e) {
  //   if ($(e.target).is(".btn-lang") === false) {
  //     $(".btn-lang").removeClass("show");
  //   }
  // });



  // // Add smooth scrolling to all links
  // $("a").on('click', function(event) {
  //   // Make sure this.hash has a value before overriding default behavior
  //   if (this.hash !== "") {
  //     // Prevent default anchor click behavior
  //     event.preventDefault();
  //     // Store hash
  //     var hash = this.hash;
  //
  //     // Using jQuery's animate() method to add smooth page scroll
  //     // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
  //     $('html, body').animate({
  //       scrollTop: $(hash).offset().top
  //     }, 1000, function(){
  //
  //       // Add hash (#) to URL when done scrolling (default click behavior)
  //       // window.location.hash = hash;
  //     });
  //   } // End if
  // });


  // if ($('.go-to-top').length) {
  //   var scrollTrigger = 1000, // px
  //   backToTop = function () {
  //     var scrollTop = $(window).scrollTop();
  //   };
  //   backToTop();
  //   $(window).on('scroll', function () {
  //     backToTop();
  //   });
  //   $('.go-to-top').on('click', function (e) {
  //     e.preventDefault();
  //     $('html,body').animate({
  //       scrollTop: 0
  //     }, 700);
  //   });
  // }


  // $(window).scroll(function() {
  //   var scroll = $(window).scrollTop();
  //
  //   if (scroll >= 200) {
  //     $(".go-to-top").addClass("show");
  //   } else {
  //     $(".go-to-top").removeClass("show");
  //   }
  // });

  // if( $('.number-counter').length) {
  //   $('.number-counter').counterUp({
  //     delay: 10,
  //     time: 2000
  //   });
  // }

  $('.carousel-events').flickity({
    // options
    cellAlign: 'left',
    contain: true,
    pageDots: false,
    fade: true,
    arrowShape: {
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
    }
  });
  $('.carousel-highlights').flickity({
    // options
    cellAlign: 'left',
    contain: true,
    pageDots: false,
    fade: true,
    arrowShape: {
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
    }
  });

  $('.post-type-archive-videos .carousel-highlights, .home .carousel-highlights').flickity({
    // options
    fade: false
  });


  $('#reports .carousel-highlights').flickity({
    // options
    groupCells: true
  });



  $('.btn-translate').click(function(e){
    $(this).parent().addClass('open');
    e.stopPropagation();
  });

  $(document).mouseup(function(e){
    var container = $(".btn-translate");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.parent().removeClass('open');
    }
  });

  $('.btn-search').click(function(ev){
    $('.box-search').toggleClass('open');
  });


  $(document).mouseup(function(ev){
    var container_2 = $(".box-search");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container_2.is(ev.target) && container_2.has(ev.target).length === 0) {
      container_2.removeClass('open');
    }
  });

  $('.event-list li').click(function(){

    $(this).addClass('active').siblings().removeClass('active');
    if ($(this).hasClass('upcoming-events-menu')) {
      $('#upcoming-events').removeClass('d-none');
      $('#past-events').addClass('d-none');
      $('.carousel-events').flickity('resize');
    } else {
      $('#upcoming-events').addClass('d-none');
      $('#past-events').removeClass('d-none');
      $('.carousel-events').flickity('resize');
    }

  });

  $('.carousel-events').each(function() {
    if ($(this).find('.carousel-cell').length === 1) {
      $(this).find('button.flickity-prev-next-button.previous, button.flickity-prev-next-button.next, ol.flickity-page-dots').hide();
    }
  });

  $('.btn-share').click(function(v){
    $('.box-share').toggleClass('show');
  });

  $(document).click(function(v){
    var container_3 = $(".box-share");
    var btn = $('.btn-share');
    // if the target of the click isn't the container nor a descendant of the container
    if ( !container_3.is(v.target) && container_3.has(v.target).length === 0 && !btn.is(v.target) && btn.has(v.target).length === 0) {
      container_3.removeClass('show');
    }
  });

  for (var i = 0; i <= 31; i++) {
    $('#upcoming-events h2:contains("'+i+'")').first().removeClass('d-none');
    $('#past-events h2:contains("'+i+'")').first().removeClass('d-none');
  }



  var time = 2;
  var $bar,
  $slider,
  $slider_nav,
  isPause,
  tick,
  percentTime;

  $slider = $('.slider');
  $slider_nav = $('.carousel-nav');
  $slider_nav.flickity({
    asNavFor: ".slider",
    contain: true,
    pageDots: false,
    prevNextButtons: false
  });
  $slider.flickity({
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false
  });

  $bar = $('.slider-progress .progress');

  // $('.slider-wrapper').on({
  //   mouseenter: function() {
  //     isPause = true;
  //   },
  //   mouseleave: function() {
  //     isPause = false;
  //   }
  // })

  function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    isPause = false;
    tick = setInterval(interval, 10);
  }


  function interval() {
    if(isPause === false) {
      percentTime += 0.4 / (time+0.1);
      $bar.css({
        width: percentTime+'%'
      });
      if(percentTime >= 100)
      {
        $slider.flickity('next', true);
        startProgressbar();
      }
    }
  }


  function resetProgressbar() {
    $bar.css({
      width: 0+'%'
    });
    clearTimeout(tick);
  }

  startProgressbar();




  if ( $('.post-type-archive-photo_gallery').length ) {

    $('.wrap-gallery').each(function(index){


      console.log(index);


      var qtd_posts = $('.wrap-gallery-'+index+' .photo-gallery-item').length;

      if (qtd_posts > 12) {
        $('.wrap-gallery-'+index+' .show-more').removeClass('d-none');
      }
      $('.wrap-gallery-'+index+' .photo-gallery-item').slice(12).hide();

      $('.wrap-gallery-'+index+' .show-more').click(function(){
        $('.wrap-gallery-'+index+' .photo-gallery-item').show();
        $('.wrap-gallery-'+index+' .show-more').addClass('d-none');
      });

    });

  }

  $('.btn-1, .africa').hover(function(){
    $('.africa, .btn-1').toggleClass('hover');
  });

  $('.btn-2, .asia').hover(function(){
    $('.asia, .btn-2').toggleClass('hover');
  });

  $('.btn-3, .europe').hover(function(){
    $('.europe, .btn-3').toggleClass('hover');
  });

  $('.btn-4, .oceania').hover(function(){
    $('.oceania, .btn-4').toggleClass('hover');
  });

  $('.btn-5, .pan-america').hover(function(){
    $('.pan-america, .btn-5').toggleClass('hover');
  });

$('.menu-menu-principal-container li').mouseover(function(){
  $(this).addClass('active').siblings().removeClass('active');
  if ($(this).hasClass('item-1')) {
    $('.menu-submenu-about-wako-container').addClass('open').siblings().removeClass('open');
  }
  if ($(this).hasClass('item-2')) {
    $('.menu-submenu-legal-container').addClass('open').siblings().removeClass('open');
  }
  if ($(this).hasClass('item-3')) {
    $('.menu-submenu-games-container').addClass('open').siblings().removeClass('open');
  }
  if ($(this).hasClass('item-4')) {
    $('.menu-submenu-results-container').addClass('open').siblings().removeClass('open');
  }
  if ($(this).hasClass('item-5')) {
    $('.menu-submenu-mediahub-container').addClass('open').siblings().removeClass('open');
  }

  if ($(this).hasClass('not-sub-item')) {
    $('.menu-submenu-about-wako-container, .menu-submenu-legal-container, .menu-submenu-games-container, .menu-submenu-results-container, .menu-submenu-mediahub-container').removeClass('open');
  }



});

$(document).mouseover(function(event) {
    if (!$(event.target).closest("header").length) {
      $('.menu-menu-principal-container li').removeClass('active');
        $('.menu-submenu-about-wako-container, .menu-submenu-legal-container, .menu-submenu-games-container, .menu-submenu-results-container, .menu-submenu-mediahub-container').removeClass('open');
    }
});




});
